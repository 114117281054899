import { useCallback, useEffect, useState } from "react";
import { Models } from "services/kohost";

import { captureException } from "services/sentry";
import {
  createFetchOptions,
  fetchHandler,
  useFetchAll,
  useMutate,
  useQueryClient,
} from "./use-fetch";
import { useLocalStorage as usLs } from "./use-local-storage";
import { useProperty } from "./use-property";
import { useScenes } from "./use-scenes";
import { useSocketIo } from "./use-socketIo";

const Room = Models.Room;

const SPACE_QUERY_KEY = "spaces";
const ALARM_QUERY_KEY = "alarms";

export const entireBuildingRoomId = "kohost-entire-building";
export const entireBuildingRoomName = "Building";

export const useRoomControl = ({
  spaces = [],
  spaceScope = [],
  currentSpaceId,
}) => {
  let { client: socketClient, connected: socketConnected } = useSocketIo();
  const { currentPropertyId } = useProperty();

  const spaceFetchOptions = createFetchOptions(
    currentPropertyId,
    SPACE_QUERY_KEY,
  );
  const alarmFetchOptions = createFetchOptions(
    currentPropertyId,
    ALARM_QUERY_KEY,
  );

  const SPACES = spaceFetchOptions.key;
  const ALARMS = alarmFetchOptions.key;

  const headers = spaceFetchOptions.headers;

  const queryClient = useQueryClient();

  const roomQueries = spaces
    .map((space) => {
      const enableQuery =
        currentSpaceId === space.id ||
        spaceScope.includes(space.id) ||
        currentSpaceId === "*";
      return {
        queryKey: [...SPACES, space.id, "rooms"],
        queryFn: fetchHandler({
          useCase: "ListRoomsInSpace",
          data: { id: space.id },
          headers,
        }),
        enabled: enableQuery,
        staleTime: 1000 * 60, // 1 minute
        refetchOnWindowFocus: true,
      };
    })
    .filter((query) => query.enabled);

  const roomQueryResults = useFetchAll({
    queries: roomQueries,
  });

  const spaceRooms = roomQueryResults
    .map((result) => result.data)
    .filter((d) => d)
    .flat();

  const firstRoomId = spaceRooms[0]?.id || "";

  const loadingRooms = roomQueryResults.some((result) => result.isLoading);

  const { data: scenes, isLoading: loadingScenes } = useScenes({
    enabled: true,
  });

  const [currentRoomId, setCurrentRoomId] = usLs(
    `space-${currentSpaceId}-currentRoomId`,
    "",
  );

  const currentRoom = spaceRooms.find((r) => r.id === currentRoomId);

  const roomIsInSpace = spaces.some((space) =>
    space.rooms?.includes(currentRoomId),
  );

  const firstThermostatId = currentRoom?.thermostats?.[0]?.id || "";

  const [currentThermostatId, setCurrentThermostatId] =
    useState(firstThermostatId);
  const [currentSourceId, setCurrentSourceId] = useState("");

  const changeRoom = (roomId) => {
    setCurrentRoomId(roomId);
    const newRoom = spaceRooms.find((r) => r.id === roomId);
    const firstThermostatId = newRoom?.thermostats?.[0]?.id || "";
    setCurrentThermostatId(firstThermostatId);
  };

  const changeThermostat = (roomId, thermostatId) => {
    setCurrentRoomId(roomId);
    setCurrentThermostatId(thermostatId);
  };

  useEffect(() => {
    if (!loadingRooms && !loadingScenes && spaces.length > 0) {
      if (!currentRoomId) {
        setCurrentRoomId(firstRoomId);
      }
      // check to make sure currentRoom id is in the array of spaces
      if (
        currentRoomId &&
        !roomIsInSpace &&
        currentRoomId !== entireBuildingRoomId
      ) {
        setCurrentRoomId(firstRoomId);
      }
    }
  }, [
    loadingRooms,
    loadingScenes,
    spaces.length,
    currentRoomId,
    setCurrentRoomId,
    firstRoomId,
    roomIsInSpace,
  ]);

  useEffect(() => {
    if (currentRoomId && !currentThermostatId) {
      setCurrentThermostatId(firstThermostatId);
    }
  }, [currentRoomId, currentThermostatId, firstThermostatId]);

  const updateRoomQueryCache = useCallback(
    (roomId, device) => {
      const deviceType = device.type;
      const deviceId = device.id;
      const spaceQueryData = queryClient.getQueriesData({
        queryKey: SPACES,
        predicate: (query) => {
          const queryData = query.state.data;
          return (
            Array.isArray(queryData) && queryData.some((s) => s.id === roomId)
          );
        },
      });

      spaceQueryData.forEach((query) => {
        const [queryKey] = query;

        // update all queries that have the room in their data
        queryClient.setQueryData(queryKey, (rooms) => {
          return rooms.map((room) => {
            if (room.id !== roomId) return room;
            else {
              const path = Room.getDevicePath(deviceType);
              const updatedRoom = structuredClone(room);
              const deviceArr = updatedRoom[path].map((d) => {
                if (d.id === deviceId) return device;
                return d;
              });

              updatedRoom[path] = deviceArr;
              return new Room(updatedRoom);
            }
          });
        });
      });

      /**
       * Here we check to see if there are any alarm config queries that have locks in their data. This will
       * allow for the locks to update in real time on the alarm zones view.
       */
      if (deviceType === "lock") {
        const alarmConfigQueryData = queryClient.getQueriesData({
          queryKey: ALARMS,
          predicate: (query) => {
            const isConfig = query.queryKey.includes("config");
            const config = query.state.data;
            const zoneLockMap = config?.zoneLockMap;
            if (!zoneLockMap) return false;
            const hasLockInConfig = Object.values(zoneLockMap).some(
              (lock) => lock.id === deviceId,
            );
            return isConfig && hasLockInConfig;
          },
        });

        alarmConfigQueryData.forEach((query) => {
          const [queryKey] = query;

          queryClient.setQueryData(queryKey, (config) => {
            const updatedConfig = structuredClone(config);
            const zoneLockMap = updatedConfig.zoneLockMap;

            for (const [zone, lock] of Object.entries(zoneLockMap)) {
              if (lock.id === deviceId) {
                zoneLockMap[zone] = { ...lock, ...device };
              }
            }

            updatedConfig.zoneLockMap = zoneLockMap;

            return updatedConfig;
          });
        });
      }
    },
    [queryClient, SPACES, ALARMS],
  );

  const currentSource =
    currentRoom?.mediaSources?.find((s) => s.id === currentSourceId) ??
    (currentRoom?.mediaSources?.length === 1
      ? currentRoom?.mediaSources?.[0]
      : null);

  const currentThermostat =
    currentRoom?.thermostats?.find((t) => t.id === currentThermostatId) ??
    (currentRoom?.thermostats?.length === 1
      ? currentRoom?.thermostats?.[0]
      : null);

  const setHandler = (useCaseName, payload) => {
    const handler = fetchHandler({
      useCase: useCaseName,
      data: payload,
      headers,
      options: { firstOnly: true },
    });

    return handler();
  };

  const setDevice = (
    { id, roomId, deviceType, ...data },
    options = { disableRemoteDeviceSet: false },
  ) => {
    try {
      const payload = { id, roomId, ...data };

      if (options.disableRemoteDeviceSet) return;
      switch (deviceType) {
        case "lock":
          if (!socketConnected)
            return setHandler("SetLock", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          else return socketClient.send("SetLock", { data: payload });
        case "switch":
          if (!socketConnected)
            return setHandler("SetSwitch", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          else return socketClient.send("SetSwitch", { data: payload });
        case "dimmer":
          if (!socketConnected)
            return setHandler("SetDimmer", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          return socketClient.send("SetDimmer", { data: payload });
        case "thermostat":
          if (!socketConnected)
            return setHandler("SetThermostat", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          else return socketClient.send("SetThermostat", { data: payload });
        case "windowCovering":
          if (!socketConnected)
            return setHandler("SetWindowCovering", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          else return socketClient.send("SetWindowCovering", { data: payload });
        case "courtesy":
          if (!socketConnected)
            return setHandler("SetCourtesy", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          else return socketClient.send("SetCourtesy", { data: payload });

        case "mediaSource":
          if (!socketConnected)
            return setHandler("SetMediaSource", payload)
              .then((device) => updateRoomQueryCache(roomId, device))
              .catch(console.error);
          else return socketClient.send("SetMediaSource", { data: payload });

        case "alarm":
          return setHandler("SetAlarm", payload)
            .then((device) => {
              updateRoomQueryCache(roomId, device);
              return device;
            })
            .catch(console.error);
        default:
          return null;
      }
    } catch (error) {
      console.error(error);
      captureException(error);
      return error;
    }
  };



  const setScene = ({ id, roomId, scene }) => {
    if (roomId) {
        return setHandler("SetRoomScene", { id, roomId, scene })
          .then((devices) => {
            Array.isArray(devices) &&
              devices?.forEach((device) =>
                updateRoomQueryCache(roomId, device),
              );
          })
          .catch(console.error);
    } else {
      return setHandler("SetScene", { id, scene })
        .then((devices) => updateRoomQueryCache(roomId, devices))
        .catch(console.error);
    }
  };

  const setSceneMutation = useMutate({
    mutationFn: setScene,
    onSuccess: (devices, vars) => {
      if(vars.roomId && Array.isArray(devices)) {
        devices.forEach((device) => updateRoomQueryCache(vars.roomId, device));
      }
    }
  })

  useEffect(() => {
    const handleDeviceUpdate = ({ data }) => {
      try {
        const { device, room } = data;
        const roomId = room.id;

        updateRoomQueryCache(roomId, device);
      } catch (error) {
        captureException(error);
      }
    };

    socketClient.subscribe("RoomDeviceUpdated", handleDeviceUpdate);

    return () => {
      socketClient.unsubscribe("RoomDeviceUpdated", handleDeviceUpdate);
    };
  }, [socketClient, updateRoomQueryCache]);

  return {
    rooms: spaceRooms,
    currentRoomId,
    currentRoom,
    changeRoom: changeRoom,
    currentSourceId,
    currentSource,
    changeSource: setCurrentSourceId,
    currentThermostatId,
    currentThermostat,
    changeThermostat: changeThermostat,
    setDevice,
    setScene: setSceneMutation,
    scenes,
    isBuilding: currentRoomId === entireBuildingRoomId,
  };
};

import { useAuth } from "hooks/use-auth";
import { useProperty } from "hooks/use-property";
import { useReservation } from "hooks/use-reservations";
import React from "react";
import { Link } from "react-router-dom";
import { getNextDateUTC, parseTime } from "utils/dates";
import Tile from "./Tile";

const ReservationStatusTile = ({ includeCheckedIn = false }) => {
  const { upcomingReservations: upcoming, checkedInReservations: checkedIn } =
    useAuth();
  const { property } = useProperty();
  const timezone = property?.timezone;
  const checkInTime = property?.checkInTime;

  const reservations = includeCheckedIn
    ? [...upcoming, ...checkedIn]
    : upcoming;

  return (
    <>
      {reservations.map((res, idx) => {
        return (
          <ReservationTile
            reservationId={res.id}
            idx={idx}
            key={idx}
            timezone={timezone}
            checkInTime={checkInTime}
            length={reservations?.length}
          />
        );
      })}
    </>
  );
};

const ReservationTile = ({
  reservationId,
  timezone,
  idx,
  length,
  checkInTime,
}) => {
  const {
    data: reservation,
    earlyCheckIn,
    roomUpgrades,
  } = useReservation(reservationId);

  const propertyCheckIn = getNextDateUTC(
    new Date(reservation.checkInDateTime),
    {
      ...parseTime(checkInTime),
      timezone,
    },
  );

  const hasEarlyCheckIn =
    new Date(reservation?.checkInDateTime) < propertyCheckIn;

  const status = reservation?.status;
  const mcStatus = reservation?.mobileCheckInStatus;
  const checkInTimeIsPassed =
    new Date() > new Date(reservation?.checkInDateTime);

  const hasEcOptions = earlyCheckIn?.data?.length > 0;
  const hasRoomOptions =
    roomUpgrades?.data?.reservation?.spaceCategoryAvailabilites?.filter(
      (opt) => opt.price > 0,
    ).length > 0;

  const spaceCategoryWasChanged =
    reservation?.mobileCheckInSpaceCategoryChanged;

  const preArrivalCompleted = [
    "ready",
    "spaceNotReady",
    "spaceNotAssigned",
    "checkInTimeNotStarted",
  ].includes(mcStatus);

  const checkInCompleted = status === "checkedIn";
  const checkInEditable =
    (hasEcOptions && !hasEarlyCheckIn) ||
    (hasRoomOptions && !spaceCategoryWasChanged);

  const getButtonLabel = () => {
    if (!preArrivalCompleted) return "Let's get started";
    if (!checkInTimeIsPassed && hasEcOptions && !hasEarlyCheckIn) return "Purchase early check-in";
    if (!checkInTimeIsPassed && !hasEcOptions && hasRoomOptions)
      return "Upgrade your room";
    return "Check in to your room";
  };

  const shouldDisplayButton = () => {
    if (checkInCompleted) return false;
    if (checkInTimeIsPassed) return true;
    if (!preArrivalCompleted) return true;
    if (checkInEditable) return true;
    return false;
  };

  const shouldAutoCheckIn = () => {
    if (!checkInTimeIsPassed) return false;
    if (checkInTimeIsPassed && preArrivalCompleted) return true;
    return false;
  };

  const getStatusMessage = () => {
    if (shouldDisplayButton()) return null;
    const statusMsg = {
      spaceNotReady: "We'll notify you when your room is ready.",
      spaceNotAssigned: "We'll notify you when your room is ready.",
      checkInTimeNotStarted: "You can check in at arrival time.",
    };
    return statusMsg[mcStatus];
  };

  const displayButton = shouldDisplayButton();
  const autoCheckIn = shouldAutoCheckIn();
  const buttonLabel = getButtonLabel();
  const statusMessage = getStatusMessage();

  const cx = length > 0 && idx + 1 === length ? "mb-0" : "mb-4";

  if (!reservation) return null;

  return (
    <Tile className={cx} key={reservation?.id}>
      <div className="mb-4 flex items-center justify-between">
        <h5 className="text-sm font-medium uppercase tracking-wider text-gray">
          Your Reservation
        </h5>
        {preArrivalCompleted && (
          <p className="text-xs font-normal text-gray">
            Pre-Arrival Completed 🎉
          </p>
        )}
        {checkInCompleted && (
          <p className="text-xs font-normal text-gray">Check-In Completed 🎉</p>
        )}
      </div>
      <ReservationDetails
        key={reservation?.id}
        mobileCheckInStatus={mcStatus}
        range={reservation?.range(timezone)}
        checkInTime={reservation?.checkInTime(timezone)}
        checkInDateTime={reservation?.checkInDateTime}
        checkOutTime={reservation?.checkOutTime(timezone)}
        confirmationNumber={reservation?.confirmationNumber}
        statusMessage={statusMessage}
      />
      {displayButton && (
        <CheckInButton
          reservationId={reservation?.id}
          label={buttonLabel}
          autoCheckIn={autoCheckIn}
        />
      )}
    </Tile>
  );
};

const CheckInButton = ({
  label = "Check in to your room",
  autoCheckIn = false,
  reservationId,
}) => {
  let link = `/check-in?reservationId=${reservationId}`;
  if (autoCheckIn) link += "&autoCheckIn=true";
  return (
    <Link
      className="flex w-full items-center justify-between rounded-sm bg-gradient-to-b from-[#70b4ff] to-[#4872dc] p-4"
      to={link}
    >
      <span className="text-lg font-medium">{label}</span>
      <Arrow color="white" />
    </Link>
  );
};

const ReservationDetails = ({
  range,
  checkInTime,
  checkOutTime,
  confirmationNumber,
  statusMessage = "",
}) => {
  return (
    <>
      <p className="mb-2 text-4xl font-semibold">{range}</p>
      <p className="mb-4">
        <span className="text-sm text-gray">Check in:</span>
        <span className="mr-4 text-sm text-white"> {checkInTime}</span>

        <span className="text-sm text-gray">Check out:</span>
        <span className="text-sm text-white"> {checkOutTime}</span>
      </p>
      {confirmationNumber && (
        <span className="-mt-4 mb-2 text-sm text-gray">
          Confirmation #{" "}
          <span className="text-white">{confirmationNumber}</span>
        </span>
      )}
      {statusMessage && (
        <p className="font-medium text-white">{statusMessage}</p>
      )}
    </>
  );
};

const Arrow = () => {
  return (
    <svg
      width="18px"
      height="16px"
      viewBox="0 0 18 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="rotate-180"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(-21.000000, -60.000000)"
          fill="currentColor"
          id="back-icon"
        >
          <path d="M24.8284271,68.6568542 L30.0710678,73.8994949 L28.6568542,75.3137085 L21,67.6568542 L22.4142136,66.2426407 L28.6568542,60 L30.0710678,61.4142136 L24.8284271,66.6568542 L38.6568542,66.6568542 L38.6568542,68.6568542 L24.8284271,68.6568542 Z"></path>
        </g>
      </g>
    </svg>
  );
};

export default ReservationStatusTile;

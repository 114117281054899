import React, { createContext, useContext } from "react";

import kohost, { Models } from "services/kohost";

import { isIOS } from "utils/browser";
import getOrganizationId, { demoOrgs } from "utils/getOrganizationId";
import { createAppBranding, createAppManifest } from "utils/theme";

import LoadingSpinner from "components/common/LoadingSpinner";

import { fetchHandler, useFetch, useMutate, useQueryClient } from "./use-fetch";
import { useLocalStorage as useLs } from "./use-local-storage";

export const propertyContext = createContext({
});

function getInitialOrganizationId() {
  const initialOrg = getOrganizationId();
  return initialOrg === "auth" ? null : initialOrg;
}

function useProvideProperty() {
  const queryClient = useQueryClient();

  const [orgId, setOrg] = React.useState(getInitialOrganizationId());

  const { data: organization, isLoading: orgLoading } = useFetch({
    queryKey: ["organization"],
    queryFn: fetchHandler({
      useCase: "DescribeMyOrganization",
      options: {
        firstOnly: true,
      },
    }),
    enabled: Boolean(orgId),
  });

  const { data: properties, isLoading: propertiesLoading } = useFetch({
    queryKey: ["properties"],
    queryFn: fetchHandler({ useCase: "ListMyProperties" }),
    enabled: Boolean(orgId),
  });

  const updatePropertySettings = (vars) => {
    const handler = fetchHandler({
      useCase: "UpdatePropertySettings",
      data: vars,
      options: {
        firstOnly: true,
      },
    });
    return handler();
  };

  const updatePropertySettingsMutation = useMutate({
    mutationFn: updatePropertySettings,
    placeholderData: {},
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["properties"] });
    },
  });

  const propertyData = properties ? properties : [];

  const fetching = orgLoading || propertiesLoading;

  const defaultId = !propertiesLoading ? propertyData[0]?.id : null;

  const [currPropId, setCurrPropId] = useLs("currentPropertyId", defaultId);

  const currentPropIdIsValid = propertyData.some((p) => p.id === currPropId);

  if (!propertiesLoading && !currPropId && propertyData?.length > 0) {
    console.log("Setting default property id");
    setCurrPropId(defaultId);
  }

  if (!propertiesLoading && !currentPropIdIsValid && propertyData?.length > 0) {
    console.log("Setting default property id");
    setCurrPropId(defaultId);
  }

  const property = propertyData?.find((p) => p.id === currPropId) || null;

  const isDemoOrg = demoOrgs.includes(orgId);

  const manifest = organization?.appManifest;

  if (manifest) {
    manifest.start_url = window.location.origin;
    createAppBranding(manifest);
    if (!isIOS() || isDemoOrg) {
      createAppManifest(manifest);
    }
  }

  const icon = manifest?.icons[0];

  const iconMedia = icon
    ? new Models.MediaFile({
        url: icon.src,
        mimeType: "image/*",
      })
    : null;

  const changeOrganization = (organizationId) => {
    kohost.organizationId = organizationId;
    setOrg(organizationId);
    setCurrPropId(null);
  };

  const isHospitality = Boolean(property?.discriminator === "hospitality");
  const isCommercial = Boolean(property?.discriminator === "commercial");
  const isEducation = Boolean(property?.discriminator === "education");

  return {
    organization,
    organizationId: orgId,
    property,
    properties: propertyData,
    currentPropertyId: currPropId,
    changeProperty: setCurrPropId,
    changeOrganization,
    icon: iconMedia,
    loading: fetching,
    isHospitality,
    isCommercial,
    isEducation,
    updateManifest: createAppManifest,
    updatePropertySettings: updatePropertySettingsMutation,
  };
}

export function ProvideProperty({ children }) {
  const config = useProvideProperty();
  const location = window.location.pathname;
  const isLoginPage = location.includes("/login");

  const propertyId = config.currentPropertyId;
  const showLoader = isLoginPage ? false : config.loading;

  return (
    <propertyContext.Provider key={propertyId} value={config}>
      {showLoader ? (
        <LoadingSpinner fullScreen height={36} width={36} />
      ) : (
        children
      )}
    </propertyContext.Provider>
  );
}

export const useProperty = () => {
  return useContext(propertyContext);
};

export default propertyContext;

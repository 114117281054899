import { cn } from "@/lib/utils";
import { useProperty } from "hooks/use-property";
import { useScreenBlur } from "hooks/use-screenBlur";
import React from "react";
import { isAppleDevice } from "utils/browser";
import { enums } from "utils/enums";
import RoomLockPopup from "./RoomLockPopup";
import { ReactComponent as LockedIcon } from "./icons/locked.svg";
import { ReactComponent as UnlockedIcon } from "./icons/unlocked.svg";

const Lock = ({ locks = [], label = "Door", onChange = function () {} }) => {
  const { property } = useProperty();
  const { toggleScreenBlur } = useScreenBlur();

  const appEnabled = property?.appFeatures?.DigitalKey?.enableApp;
  const isSystemOnline = property.appFeatures?.DigitalKey?.systemOnline;

  const isApple = isAppleDevice();
  const appLocation = isApple
    ? enums.roomKeyIosAppUrl
    : enums.roomKeyAndroidAppUrl;

  const [popupOpen, setPopupOpen] = React.useState(false);

  const handleOpen = () => {
    if (!appEnabled) {
      setPopupOpen(true);
      toggleScreenBlur();
      return;
    }

    if (!isSystemOnline) {
      try {
        window.open(appLocation, "_self");
        //throw new Error("Room Key app is not installed");
      } catch (error) {
        console.log({ error });
      }
    } else {
      setPopupOpen(true);
      toggleScreenBlur();
    }
  };

  const handleClose = () => {
    setPopupOpen(false);
    toggleScreenBlur();
  };

  const handleSetLock = (data) => {
    onChange({ deviceType: "lock", ...data });
    if (data.state === "unlocked") {
      handleClose();
    }
  };

  const lockState =
    isSystemOnline && locks.some((l) => l.state === "unlocked")
      ? "unlocked"
      : "locked";

  if (!locks?.length) return null;

  return (
    <>
      <div
        className={cn("peer fixed bottom-4 right-4 z-10", {
          "lock-open": popupOpen,
          "lock-closed": !popupOpen,
        })}
      >
        <button
          className="flex h-20 w-20 flex-col items-center justify-center rounded-full bg-white shadow-xl"
          onClick={(e) => handleOpen(e)}
        >
          <span className="mb-1 fill-[#6C778C]">
            {lockState === "locked" && <LockedIcon color="#6C778C" />}
            {lockState === "unlocked" && <UnlockedIcon color="#44b078" />}
          </span>
          <label className="text-sm font-medium text-[#40495b]">{label}</label>
        </button>
      </div>
      <RoomLockPopup
        show={popupOpen}
        locks={locks}
        onSetLock={handleSetLock}
        onClose={handleClose}
        displayAppDownload={appEnabled}
      />
    </>
  );
};

export default Lock;
